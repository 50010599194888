import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { routes } from "@/utils/routes"
import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import WistiaPlayer from "@/components/WistiaPlayer"

type PageProps = {
  data: any
}

const TransformingTheCampus: React.FC<PageProps> = ({ data }) => (
  <Layout
    className="page-id-7"
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/transform-1.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Transforming the Campus"
        />
        <div className="container text-white pt-80 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading">
            TRANSFORMING THE <span className="text-primary">CAMPUS</span>
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Transforming the Campus" />
    <div className="py-24">
      <div className="container">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <div>
            <h2 className="uppercase text-[36px] sm:text-[46px] font-medium leading-[0.9] pb-4">
              Help us create a <span className="text-primary">learning</span>{" "}
              environment which will{" "}
              <span className="text-primary">inspire leaders</span> and
              entrepreneurs
            </h2>

            <p className="leading-relaxed">
              Science and technology underpin every aspect of modern life, and
              future technological, economic and cultural progress will be
              driven by societies with high levels of scientific and
              technological expertise.
            </p>
          </div>
          <WistiaPlayer
            videoStyle={{
              width: "100%",
              height: "100%",
              minHeight: "300px",
              maxHeight: "340px",
            }}
            videoID="u5wu5dsgzl"
          />
          <Link className="btn btn-secondary btn-lg" to={routes.innovation()}>
            Explore the Centre of Innovation
          </Link>
          <Link
            className="btn btn-primary btn-lg"
            to={routes.scienceDepartmentRenovation()}
          >
            Explore the Science Department Renovation
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default TransformingTheCampus
